
import {
    defineComponent, reactive, ref
} from 'vue';
import List from '@/components/common/list/src/list.vue';
import { deviceApi } from '@/api';
import CameraDevicesOperateDialog from '@/views/installer/user/components/camera-devices-operate-dialog.vue';
import AddButton from '@/components/common/add-button/index.vue';
import { overlayFormData } from '@/util/system';
import { ListActionItem } from '@/components/common/list';
import removeFunc from '@/methods/remove-func';

export default defineComponent({
    components: {
        List,
        CameraDevicesOperateDialog,
        AddButton
    },
    props: {
        account: {
            type: String
        },
        // 设备类型 akuvox设备 和 第三方设备
        type: {
            type: String,
            default: 'intercom'
        },
        // 第三方设备三级菜单标识
        deviceType: {
            type: String,
            default: 'camera'
        },
        personalAccountUUID: {
            type: String,
            default: ''
        },
        owner: {
            type: String,
            default: ''
        }
    },
    setup(props) {
        // 获取build和room框数据
        const updateData = ref(0);
        // 搜索条件
        const formData = reactive({
            Account: props.account,
            Location: '',
            type: props.type,
            deviceType: props.deviceType,
            UUID: props.personalAccountUUID,
            row: 10,
            page: 1
        });
        overlayFormData(formData);

        // 表头   迁移name 改成prop,增加 {
        //     name: 'op',
        //         label: WordList.CaptureListTanleAction
        // }
        const column = [
            {
                name: 'Owner',
                label: WordList.TabelPersonDeviceInHtmlOwner
            }, {
                name: 'Location',
                label: WordList.TabelPersonDeviceInHtmlLocation
            }, {
                name: 'LinkDevice',
                label: WordList.LinkDevice
            }, {
                name: 'CreateTime',
                label: WordList.TabelMessageBoxCreateTime
            }
        ];

        // 表格数据获取
        const tableData = reactive<{row: object[];detail: object[];total: number}>({
            row: [],
            detail: [],
            total: 0
        });

        // 获取第三方设备数据
        const getThirdPartyDeviceApi = () => {
            deviceApi.thirdPartCameraGetListForIns(
                formData,
                (res: {row: object[];detail: object[];total: number}) => {
                    tableData.row = res.row;
                    tableData.detail = res.detail;
                    tableData.total = res.total;
                    updateData.value += 1;
                }
            );
        };
        getThirdPartyDeviceApi();

        // 操作
        const isShowOperateDialog = ref(false);
        const operateType = ref<'add'|'edit'>('add');
        const add = () => {
            operateType.value = 'add';
            isShowOperateDialog.value = true;
        };
        const editData = ref({});

        const edit = (item: object) => {
            operateType.value = 'edit';
            editData.value = item;
            isShowOperateDialog.value = true;
        };

        // 删除
        const deleteThirdPartyDeviceApi = (data: { UUID: string }) => {
            removeFunc(WordList.TabelDeleteText, () => {
                deviceApi.thirdPartCameraDelete({ UUID: data.UUID }, getThirdPartyDeviceApi);
            });
        };

        // action操作 迁移备注：这部分不用迁移 主要是为了适配删除触发点击
        const action: ListActionItem[] = [{
            type: 'edit2',
            event(data: { UUID: string }) {
                edit(data);
            },
            class: 'el-icon-my-modify modify-icon',
            title: WordList.TabelConfigInHtmlModify
        }, {
            type: 'delete2',
            event(data: { UUID: string }) {
                deleteThirdPartyDeviceApi(data);
            },
            class: 'el-icon-delete delete-icon',
            title: WordList.TabelConfigInHtmlDelete
        }];

        const cameraClose = () => {
            isShowOperateDialog.value = false;
        };

        return {
            formData,
            column,
            getThirdPartyDeviceApi,
            tableData,
            updateData,
            isShowOperateDialog,
            edit,
            editData,
            cameraClose,
            add,
            operateType,
            action
        };
    }
});
