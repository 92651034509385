
import {
    defineComponent, PropType, reactive, ref
} from 'vue';
import { CameraOperateAdd, CameraOperateEdit, LinkDeviceList } from '@/views/installer/user/types/camera-devices';
import DialogShell from '@/components/common/dialog-shell/index.vue';
import { deviceApi } from '@/api';
import List from '@/components/common/list/src/list.vue';
import DeviceIcon from '@/components/view/common/device-icon/index.vue';
import { checkRtspAddress, checkRtspPassword, checkRtspUserName } from '@/methods/rules/device';

export default defineComponent({
    components: {
        DialogShell,
        List,
        DeviceIcon
    },
    props: {
        operate: {
            type: String as PropType<'add'|'edit'>,
            default: 'add'
        },
        cameraInfo: {
            type: Object as PropType<CameraOperateEdit>,
            default: {}
        },
        personalAccountUUID: {
            type: String,
            default: ''
        },
        owner: {
            type: String,
            default: ''
        }
    },
    emits: ['close', 'success'],
    setup(props, { emit }) {
        const formRef = ref();
        const formData = props.operate === 'add'
            ? reactive<CameraOperateAdd>({
                Owner: props.owner,
                Location: '',
                RtspAddress: 'rtsp://',
                RtspUserName: '',
                RtspPwd: '',
                IsLinkDevice: 0,
                MAC: '',
                PersonalAccountUUID: props.personalAccountUUID
            })
            : reactive<CameraOperateEdit>({
                ...props.cameraInfo
            });

        // 编辑保存初始勾选的MAC
        const initMAC = props.operate === 'edit' && formData.MAC;

        const rules = {
            Location: [
                { required: true, message: WordList.PleaseEnterTheFormat.format(WordList.TabelPersonDeviceInHtmlLocation), trigger: 'change' },
                { max: 63, message: WordList.LocationTooLong, trigger: 'change' }
            ],
            RtspAddress: [
                {
                    validator: checkRtspAddress,
                    trigger: 'change'
                },
                { max: 512, message: WordList.InvalidRTSPAddress, trigger: 'change' }
            ],
            RtspUserName: [
                { required: true, message: WordList.PleaseEnterTheFormat.format(WordList.UserName.toLocaleLowerCase()), trigger: 'change' },
                { max: 63, message: WordList.UserNameTooLong, trigger: 'change' },
                {
                    validator: checkRtspUserName,
                    trigger: 'change'
                }
            ],
            RtspPwd: [
                { required: true, message: WordList.PleaseEnterTheFormat.format(WordList.TabelPersonUserInHtmlPassword), trigger: 'change' },
                { max: 63, message: WordList.RtspPwdTooLong, trigger: 'change' },
                {
                    validator: checkRtspPassword,
                    trigger: 'change'
                }
            ]
        };

        // link Device
        const column = [
            {
                name: 'Radio',
                label: '',
                type: 'customize'
            }, {
                name: 'Location',
                label: WordList.TabelPersonDeviceInHtmlLocation
            }, {
                name: 'MAC',
                label: WordList.TabelPersonDeviceInHtmlMAC
            }, {
                name: 'DeviceType',
                label: WordList.TabelPersonDeviceInHtmlDeviceTypeLabel,
                type: 'customize'
            }
        ];

        const updateData = ref(0);
        const tableData = ref<LinkDeviceList[]>([]);

        const isLinkedMac = ref<string[]>([]);
        deviceApi.thirdPartCameraGetLinkDeviceList({
            PersonalAccountUUID: props.personalAccountUUID
        }, (res: {LinkDevice: LinkDeviceList[];IsLinkedMAC: string[]}) => {
            tableData.value = res.LinkDevice;
            isLinkedMac.value = res.IsLinkedMAC;
            updateData.value += 1;
        });

        function close() {
            emit('close');
        }

        function submit() {
            formRef.value.validate((valid: boolean) => {
                if (valid) {
                    const fn = props.operate === 'add'
                        ? deviceApi.addThirdPartCamera
                        : deviceApi.editThirdPartCamera;

                    fn({
                        ...formData
                    }, () => {
                        emit('success');
                        close();
                    });
                }
            });
        }

        return {
            formRef,
            formData,
            submit,
            rules,
            tableData,
            column,
            updateData,
            close,
            initMAC,
            isLinkedMac
        };
    }
});
